import Layout from "../layout/layout";

const Koumparoi: React.FC = () => {
  return (
    <Layout title="Koumparoi" activeSection="koumparoi">
      <div>
        In a Greek Orthodox wedding, the koumparoi (plural of koumbaros or
        koumbara) play a significant and cherished role as spiritual sponsors
        for the couple. Traditionally, the koumbaros (best man) or koumbara
        (maid of honour) assists in performing key rituals during the ceremony,
        such as placing the stefana (wedding crowns) on the couple's heads and
        guiding them around the altar during the "Dance of Isaiah." {"\n"}
      </div>
      <div>
        Beyond the ceremony, the koumparoi serve as lifelong mentors and
        supporters, symbolizing a spiritual bond with the couple. This role is
        often given to someone deeply trusted and respected by the bride and
        groom, making it an honor and a meaningful connection within the
        marriage.
      </div>
    </Layout>
  );
};

export default Koumparoi;
