import Layout from "../layout/layout";
import "./wedding.css";

const About: React.FC = () => {
  return (
    <Layout activeSection="wedding" title="My Big Fat Greek Chinese Wedding">
      <div className="about">
      </div>
    </Layout>
  )
}

export default About;