import Layout from "../layout/layout"
import './registry.css'

const Registry: React.FC = () => {
  return (
    <Layout activeSection="registry">
      <div className="registry">
      Your presence at our wedding is the greatest gift of all. However, if you would like to honor us with a gift, we would greatly appreciate contributions towards [specific use, e.g., "our honeymoon fund" or "a special project"]. A gift registry is not available, but if you wish to contribute, please find details below. Thank you for your understanding and support."
      </div>
    </Layout>
  )
}

export default Registry;